export default {
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawerAdmin = payload
  },
  SET_SCRIM (state, payload) {
    state.barColor = payload
  },
  SET_SLIDERS_ADMIN: (state, payload) => {
    state.slidersAdmin = payload
  },
}
