export default {
  // SET_DRAWER_WEB: state => (state.drawerWeb = !state.drawerWeb),
  // setDrawer: (state, payload) => (state.drawer = payload),
  SET_DRAWER_WEB: (state, payload) => (state.drawerWeb = payload),

  SET_MENU: (state, payload) => {
    state.menuEspecialidades = false
    state.menuServicios = false
    state.menuCharlas = false
    state.menuProblemas = false

    switch (payload.item) {
      case 1:
        state.menuEspecialidades = payload.value
        break
      case 2:
        state.menuServicios = payload.value
        break
      case 3:
        state.menuProblemas = payload.value
        break
      case 4:
        state.menuCharlas = payload.value
        break
    }
  },
  SET_HOVER_SUBMENU: (state, payload) => {
    state.hoverSubmenu = payload
  },
  SET_SUCURSALES: (state, payload) => {
    state.sucursales = payload.filter(suc => ![4].includes(suc.CodSucursal))
  },
  SET_ESPECIALIDADES_MENU: (state, payload) => {
    state.especialidadesMenu = payload
  },
  SET_SERVICIOS_MENU: (state, payload) => {
    state.serviciosMenu = payload
  },
  SET_NOTICIAS_HOME: (state, payload) => {
    state.noticiasHome = payload
  },
  SET_CHARLAS_MENU: (state, payload) => {
    state.charlasMenu = payload
  },
  SET_TESTIMONIOS: (state, payload) => {
    state.testimonios = payload
  },
  SET_PROBLEMAS_TIPOS_MENU: (state, payload) => {
    state.problemasTiposMenu = payload
  },
  SET_PREVISIONES_CONVENIOS: (state, payload) => {
    state.previsionesConvenios = payload
  },
  SET_SLIDERS_PRINCIPAL: (state, payload) => {
    state.slidersPrincipal = payload
  },
  SET_SLIDERS_BIENVENIDOS: (state, payload) => {
    state.slidersBienvenidos = payload
  },
  SET_MENSAJE_SITIO: (state, payload) => {
    state.mensajeSitio = payload
  },
  SET_CLOUDINARY_VERSION: (state, payload) => {
    state.cldVersion = payload
  },
  SET_HORAS_ONLINE_VERSION: (state, payload) => {
    state.horasOnlineVersion = payload
  },
}
