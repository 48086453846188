import axios from 'axios'

export default {
  getSucursales ({ commit }) {
    axios.get('/sucursales').then(response => {
      commit('SET_SUCURSALES', response.data.result)
    })
  },
  getNoticiasHome ({ commit }) {
    axios.post('/noticias/home', { desde: 0, limite: 6 }).then(response => {
      commit('SET_NOTICIAS_HOME', response.data.result)
    })
  },
  getTestimonios ({ commit }) {
    axios.get('/testimonios').then(response => {
      commit('SET_TESTIMONIOS', response.data.result)
    })
  },
  getEspecialidadesMenu ({ commit }, suc) {
    axios.post('/especialidades/menu').then(response => {
      commit('SET_ESPECIALIDADES_MENU', response.data.result)
    })
  },
  getServiciosMenu ({ commit }, suc) {
    axios.get('/servicios').then(response => {
      commit('SET_SERVICIOS_MENU', response.data.result)
    })
  },
  getProblemasTiposMenu ({ commit }) {
    axios.post('/problemas-tipos').then(response => {
      commit('SET_PROBLEMAS_TIPOS_MENU', response.data.result)
    })
  },
  getCharlasMenu ({ commit }) {
    axios.post('/charlas/menu').then(response => {
      commit('SET_CHARLAS_MENU', response.data.result)
    })
  },
  getPrevisionesTodas ({ commit }) {
    axios.get('/previsiones/todas').then(response => {
      commit('SET_PREVISIONES_TODAS', response.data.result)
    })
  },
  getPrevisionesConvenios ({ commit }) {
    // Convenios
    axios.get('/previsiones/convenios').then(response => {
      commit('SET_PREVISIONES_CONVENIOS', response.data.result)
    })
  },
  getSlidersPrincipal ({ commit }) {
    axios.get('/sliders-principal').then(response => {
      commit('SET_SLIDERS_PRINCIPAL', response.data.result)
    })
  },
  getSlidersBienvenidos ({ commit, state }) {
    axios.get('/sliders-bienvenidos').then(response => {
      commit('SET_SLIDERS_BIENVENIDOS', response.data.result)
    })
  },
  getMensajeSitio ({ commit, state }) {
    axios.get('/mensaje-sitio').then(response => {
      commit('SET_MENSAJE_SITIO', response.data.result)
    })
  },
  getCldVersion ({ commit, state }) {
    axios.post('/cloudinary/version').then(response => {
      commit('SET_CLOUDINARY_VERSION', response.data.timestamp)
    })
  },
  getHorasOnlineVersion ({ commit, state }) {
    axios.get('/horas-online/version').then(response => {
      commit('SET_HORAS_ONLINE_VERSION', response.data.result)
    })
  },
}
