<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      max-width="450"
      persistent
    >
      <v-card>
        <v-card-title
          class="text-h5"
          v-html="value.Titulo"
        />

        <v-card-text v-html="value.Contenido" />

        <v-card-text v-html="value.Pie" />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click.stop="cerrar()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ShowDialogMensajeSitio',
    props: {
      value: {
        Activo: {
          type: Boolean,
          default: false,
        },
        Contenido: {
          type: String,
          default: '',
        },
        Pie: {
          type: String,
          default: '',
        },
        default: null,
        type: Object,
      },
    },
    computed: {
      show: {
        get () {
          return this.value.Activo
        },
        set (value) {
          this.$emit('input', value.Activo)
        },
      },
    },
    methods: {
      cerrar () {
        this.value.Activo = false
      },
    },
  }
</script>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
