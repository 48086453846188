<template>
  <div class="text-center">
    <v-dialog
      v-model="value.active"
      max-width="1400"
      persistent
      scrollable
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="validar()">
          <v-card>
            <v-card-title class="dialog-problema-titulo text-h5">
              <h3>
                {{
                  value.problema.CodProblema ? `Editando: ` : 'Nuevo Problema'
                }}
              </h3>
              <span
                v-if="value.problema.NombreProblema"
                v-html="value.problema.NombreProblema"
              />
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-12">
                  <v-row>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="El Problema debe incluir un nombre"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Nombre
                            </label>
                          </template>
                          <span>Nombre del Tipo de Problema</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.problema.NombreProblema"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:50"
                        name="El Problema debe incluir un enlace"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Enlace<span class="label-span">
                                (<b>formato:</b>
                                nuevo-tipo-problema-enlace)</span>
                            </label>
                          </template>
                          <span>URL que se asignará al Tipo de Problema</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.problema.ToLink"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:250"
                        name="El Problema debe incluir un Meta Title"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Meta Title
                            </label>
                          </template>
                          <span>Título a mostrar en pestaña (considerar SEO)</span>
                        </v-tooltip>

                        <v-text-field
                          v-model="value.problema.MetaTitle"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      v-if="value.problema.Imagen"
                      class="col-12 col-md-6"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="label-img"
                            v-on="on"
                          >Imagen actual: <span
                            v-html="value.problema.Imagen"
                          /></label>
                        </template>
                        <span>La imagen actual del Poblema. Puede cambiarla en el
                          box "Cambiar Imagen"</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Archivo"
                        rules="ext:jpg,png|size:1000"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                              v-html="
                                value.problema.Imagen
                                  ? 'Cambiar Imagen'
                                  : 'Subir Imagen'
                              "
                            />
                          </template>
                          <span>Cambia la imagen actual asociada al Problema</span>
                        </v-tooltip>
                        <v-file-input
                          v-model="value.imagen"
                          small-chips
                          :error-messages="errors"
                          label="Formato: jpg, png (1200x400px sugeridos)"
                          dense
                          filled
                          color="success"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12">
                      <h4>Descripción</h4>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:8000"
                        name="Descripcion"
                      >
                        <vue-editor
                          v-model="value.problema.Descripcion"
                          :error-messages="errors"
                          :editor-toolbar="editorToolbarOptions"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="cerrar()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="success"
                default
                type="submit"
                :disabled="invalid"
                :loading="value.loader"
              >
                Grabar
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
  import { VueEditor } from 'vue2-editor'
  export default {
    name: 'ShowDialogTipoProblema',
    components: {
      VueEditor,
    },
    props: {
      value: {
        active: {
          type: Boolean,
          default: false,
        },
        problema: {
          type: Object,
          default: false,
        },
        imagen: {
          type: Object,
          default: false,
        },
        loader: {
          type: Boolean,
          default: false,
        },
        default: null,
        type: Object,
      },
    },
    data () {
      return {
        editorToolbarOptions: require('@/data/editorToolbarOptions.json'),
      }
    },
    watch: {
      'value.active': function (value) {
        requestAnimationFrame(() => {
          // Reseteamos vee al cerrar dialog
          this.$refs.observer.reset()
        })
      },
    },
    methods: {
      validar () {
        this.$emit('procesar', this.value)
      },
      cerrar () {
        this.$emit('cerrar')
      },
    },
  }
</script>

<style lang="sass" scoped>
@import '@/sass/variables2'

.pre-formatted
  white-space: pre

.validation-error-box
  border: 1px solid #ff6668

.validation-error-title
  color: #ff6668

.ql-editor
  height: 200px !important

.chk-problemas__main
  overflow-y: scroll
  height: 1220px

  .label-img
  color: #9e9e9e
  span
    color: $orange

.label-img
  color: #9e9e9e
  span
    color: $orange
.v-card
  .dialog-problema-titulo
    h3
      color: $green !important
      display: inline
    span
      color: #9e9e9e
      text-transform: capitalize
      font-weight: bold
.label-span
  font-size: 13px !important
  color: $green
</style>
