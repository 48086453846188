export default {
  sucursales: [],
  rrss: require('@/data/rrss.json'),
  rrssLanding: require('@/data/rrssLanding.json'),
  previsionesConvenios: '',
  especialidadesMenu: '',
  serviciosMenu: '',
  problemasTiposMenu: '',
  charlasMenu: '',
  porQueConsultar: require('@/data/porQueConsultar.json'),
  portalPacientes: require('@/data/portalPacientes.json'),
  triptItems: require('@/data/triptItems.json'),
  testimonios: '',
  noticiasHome: '',
  drawerWeb: false,
  menuEspecialidades: false,
  menuServicios: false,
  menuCharlas: false,
  menuProblemas: false,
  slidersPrincipal: '',
  slidersBienvenidos: '',
  mensajeSitio: '',
  cldVersion: '',
  horasOnlineVersion: '',
  hoverSubmenu: false
}
