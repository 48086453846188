import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import Vuelidate from 'vuelidate'
import Cloudinary from 'cloudinary-vue'
import VueMeta from 'vue-meta'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueAnimateOnScroll)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2, options)
// Vue.use(VueLazyload)

Vue.use(Cloudinary, {
  configuration: {
    cloudName: 'dkod5pdlc',
    secure: true,
  },
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

// Agregamos la URL base de nuestra API
// axios.defaults.baseURL = 'https://api2.verdadancestral.com'
axios.defaults.baseURL = 'https://api2.terapia.cl'
// axios.defaults.baseURL = 'http://127.0.0.1:8080'

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
