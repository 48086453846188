import { render, staticRenderFns } from "./ShowDialogMensajeSitio.vue?vue&type=template&id=07b2d061"
import script from "./ShowDialogMensajeSitio.vue?vue&type=script&lang=js"
export * from "./ShowDialogMensajeSitio.vue?vue&type=script&lang=js"
import style0 from "./ShowDialogMensajeSitio.vue?vue&type=style&index=0&id=07b2d061&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports