export default {
  splitEspecialidadesMenu: state => {
    const esp1 = []
    const esp2 = []
    state.especialidadesMenu.map(especialidad => {
      if (especialidad.CodSiat === 8 || especialidad.CodSiat === 9) {
        esp1.push(especialidad)
      } else {
        esp2.push(especialidad)
      }
    })

    return {
      esp1,
      esp2,
    }
  },
  splitProblemasTiposMenu: state => {
    let i
    let j
    const PTH = []
    const chunk = 4
    const array = state.problemasTiposMenu

    for (i = 0, j = array.length; i < j; i += chunk) {
      const obj = Object.assign({}, array.slice(i, i + chunk))
      PTH.push(obj)
    }

    return PTH
  },

  splitServiciosMenu: state => {
    let i
    let j
    const SH = []
    const chunk = 3
    const array = state.serviciosMenu

    for (i = 0, j = array.length; i < j; i += chunk) {
      const obj = Object.assign({}, array.slice(i, i + chunk))
      SH.push(obj)
    }

    return SH
  },

  splitCharlasMenu: state => {
    let i
    let j
    const SH = []
    const chunk = 5
    const array = state.charlasMenu

    for (i = 0, j = array.length; i < j; i += chunk) {
      const obj = Object.assign({}, array.slice(i, i + chunk))
      SH.push(obj)
    }

    return SH
  },
}
