<template>
  <div class="text-center show-dialog__charla">
    <v-dialog
      v-model="value.active"
      max-width="1400"
      persistent
      scrollable
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="validar()">
          <v-card class="dialog-charla-card">
            <v-row style="position: fixed;">
              <v-col class="text-left pa-0">
                <v-btn
                  icon
                  @click.stop="cerrar()"
                >
                  <v-icon
                    large
                    :small="$vuetify.breakpoint.xs"
                  >
                    {{ $vuetify.icons.values.close }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-card-title class="dialog-charla-titulo text-h5">
              <h3>
                {{ value.charla.CodCharla ? `Editando: ` : 'Nueva charla' }}
              </h3>
              <span
                v-if="value.charla.Titulo"
                v-html="value.charla.Titulo"
              />
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-12">
                  <v-row class="row-validations">
                    <v-col class="col-12">
                      <v-row class="row-validations__title">
                        <v-col class="main">
                          <h3>Información</h3>
                        </v-col>
                      </v-row>
                      <v-row class="row-validations__content">
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:100"
                            name="Título"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Título
                                </label>
                              </template>
                              <span>Título de la charla</span>
                            </v-tooltip>
                            <v-text-field
                              v-model="value.charla.Titulo"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:250"
                            name="Meta Title"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Meta Title
                                </label>
                              </template>
                              <span>Título a mostrar en pestaña (considerar
                                SEO)</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.MetaTitle"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:250"
                            name="Hora"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Hora
                                </label>
                              </template>
                              <span>Hora en que comenzará la charla</span>
                            </v-tooltip>
                            <v-menu
                              ref="menu"
                              v-model="timePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="value.charla.Hora"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="value.charla.Hora"
                                  label="Seleccione una hora"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  filled
                                  color="info"
                                  v-on="on"
                                />
                              </template>
                              <v-time-picker
                                v-if="timePicker"
                                v-model="value.charla.Hora"
                                :error-messages="errors"
                                full-width
                                color="info"
                                format="24hr"
                                @click:minute="
                                  $refs.menu.save(value.charla.Hora)
                                "
                              />
                            </v-menu>
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Fecha"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Fecha
                                </label>
                              </template>
                              <span>Día en que se dictará la charla</span>
                            </v-tooltip>
                            <v-menu
                              v-model="datePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="value.charla.Fecha"
                                  label="Seleccione una fecha"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  filled
                                  color="info"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker
                                v-model="value.charla.Fecha"
                                color="info"
                                :error-messages="errors"
                                locale="es-ES"
                                :disabled="porDefinir"
                                @input="datePicker = false"
                              />
                            </v-menu>
                          </validation-provider>
                          <v-checkbox
                            v-model="porDefinir"
                            label="Sin Fecha (Por Definir)"
                            class="por-definir"
                          />
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Imagen"
                            rules="ext:jpg,png|size:1000"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text label-charla"
                                  v-on="on"
                                  v-html="
                                    value.charla.Imagen
                                      ? `Cambiar Imagen (Ver imagen actual: <a style='color: orange !important;' href='https://res.cloudinary.com/dkod5pdlc/image/upload/v${cldVersion}/terapiacl/charlas-y-talleres/charlas-imagenes-principales/${value.charla.Imagen}' target='_blank'>${value.charla.Imagen}</a>)`
                                      : 'Subir Imagen'
                                  "
                                />
                              </template>
                              <span>Cambia la imagen actual asociada a la
                                charla</span>
                            </v-tooltip>
                            <v-file-input
                              v-model="value.imagen"
                              small-chips
                              :error-messages="errors"
                              label="Formato: jpg, (1200x400px sugeridos)"
                              dense
                              filled
                              color="info"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Imagen Miniatura"
                            rules="ext:jpg,png|size:1000"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text label-charla"
                                  v-on="on"
                                  v-html="
                                    value.charla.ImagenMiniatura
                                      ? `Cambiar Imagen Miniatura (Ver imagen actual: <a style='color: orange !important;' href='https://res.cloudinary.com/dkod5pdlc/image/upload/v${cldVersion}/terapiacl/charlas-y-talleres/charlas-imagenes-miniaturas/${value.charla.Imagen}' target='_blank'>${value.charla.Imagen}</a>)`
                                      : 'Subir Imagen Miniatura'
                                  "
                                />
                              </template>
                              <span>Cambia la Imagen en miniatura actual asociada a
                                la charla (la que se muestra en el listado).
                                Debe ser una imagen cuadrada, idealmente de
                                400x400px</span>
                            </v-tooltip>
                            <v-file-input
                              v-model="value.imagenMiniatura"
                              small-chips
                              :error-messages="errors"
                              label="Formato: jpg, (400x400px sugeridos)"
                              dense
                              filled
                              color="info"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:50"
                            name="Lugar"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Lugar
                                </label>
                              </template>
                              <span>Lugar donde se dictará la charla</span>
                            </v-tooltip>
                            <v-select
                              v-model="value.charla.Lugar"
                              :error-messages="errors"
                              :items="lugares"
                              item-value="Nombre"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:100"
                            name="Relator"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Relator
                                </label>
                              </template>
                              <span>Datos del relator que dictará la charla</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.Relator"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:100"
                            name="Mostrar en menú"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Mostrar en menú
                                </label>
                              </template>
                              <span>Especifica si la charla estará visible en el
                                menú del sitio</span>
                            </v-tooltip>
                            <v-select
                              v-model="value.charla.InMenu"
                              :items="[
                                { id: 1, desc: 'Sí' },
                                { id: 0, desc: 'No' },
                              ]"
                              :error-messages="errors"
                              filled
                              item-text="desc"
                              item-value="id"
                              color="info"
                              label="En menú"
                              value="1"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col
                          v-if="value.charla.InMenu"
                          class="col-12 col-md-6"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|numeric|max:3"
                            name="Orden en Menú"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Orden en Menú
                                </label>
                              </template>
                              <span>Orden que tendrá en el menú, en caso de ser
                                mostrado</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.Orden"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:255"
                            name="Dirigido"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Dirigido
                                </label>
                              </template>
                              <span>Segmento al que está dirigida la charla</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.Dirigido"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:100"
                            name="Duración"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Duración
                                </label>
                              </template>
                              <span>Tiempo cronológico que durará la charla</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.Duracion"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:50|alpha_dash"
                            name="Enlace"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Enlace (formato: nueva-charla-providencia)
                                </label>
                              </template>
                              <span>URL que tendrá la charla al abrirla en
                                navegador</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.ToLink"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:20"
                            name="Valor"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Valor
                                </label>
                              </template>
                              <span>Valor que tendrá la charla</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.Valor"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:50"
                            name="Modalidad"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Modalidad
                                </label>
                              </template>
                              <span>Modalidad (charla, taller, etc...)</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.charla.Modalidad"
                              :error-messages="errors"
                              color="info"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12">
                          <h4>Introducción</h4>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:5000"
                            name="Introducción"
                          >
                            <vue-editor
                              v-model="value.charla.Introduccion"
                              :error-messages="errors"
                              :editor-toolbar="editorToolbarOptions"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="row-breakdown-items">
                    <v-col>
                      <v-row class="row-breakdown-items__title">
                        <v-col class="main">
                          <h3>
                            Meta Tags
                            <v-icon dark>
                              {{ $vuetify.icons.values.tagPlus }}
                            </v-icon>
                          </h3>
                          <v-btn
                            color="info"
                            :small="$vuetify.breakpoint.xs"
                            @click="abrirCrearMetaTag()"
                          >
                            <v-icon>
                              {{ $vuetify.icons.values.plusBox }}
                            </v-icon>
                            Crear Nuevo MetaTag
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="row-breakdown-items__content">
                        <v-col class="col-12">
                          <v-row class="row-breakdown-items__form">
                            <v-col class="col-12 col-md-6 inputs">
                              <v-text-field
                                v-model="value.metaTagBox.name"
                                label="Name"
                                solo
                              />
                              <v-text-field
                                v-model="value.metaTagBox.content"
                                label="Content"
                                solo
                              />
                              <v-btn
                                v-if="value.metaTagModoCreacion"
                                color="info"
                                @click="crearMetaTag()"
                              >
                                Crear
                              </v-btn>
                              <v-btn
                                v-else
                                @click="editarMetaTag()"
                              >
                                editar
                              </v-btn>
                            </v-col>
                            <v-col class="col-12 col-md-6 alerts">
                              <div class="alert-main">
                                <v-alert
                                  v-if="showMsgMetaTag"
                                  dense
                                  text
                                  :type="typeMsgMetaTag"
                                >
                                  <span v-html="msgMetaTag" />
                                </v-alert>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            class="row-breakdown-items__table justify-center"
                          >
                            <v-col class="col-12">
                              <div class="divider" />
                            </v-col>
                            <v-col
                              v-if="
                                value.charla.MetaTags != undefined &&
                                  value.charla.MetaTags.length > 0
                              "
                              class="col-12 col-md-9 mt-5 mb-5 table-main"
                            >
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Name
                                      </th>
                                      <th class="text-left">
                                        Content
                                      </th>
                                      <th>
                                        Editar
                                      </th>
                                      <th>
                                        Eliminar
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in value.charla.MetaTags"
                                      :key="i"
                                    >
                                      <td>{{ item.Name }}</td>
                                      <td>{{ item.Content }}</td>
                                      <td class="td-btn">
                                        <v-btn
                                          color="info"
                                          small
                                          @click="
                                            abrirEditarMetaTag(
                                              value.charla.MetaTags.indexOf(
                                                item
                                              )
                                            )
                                          "
                                        >
                                          <v-icon>{{ $vuetify.icons.values.menuDown }}</v-icon>
                                        </v-btn>
                                      </td>
                                      <td class="td-btn">
                                        <v-btn
                                          color="info"
                                          small
                                          @click="
                                            eliminarMetaTag(
                                              value.charla.MetaTags.indexOf(
                                                item
                                              )
                                            )
                                          "
                                        >
                                          <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                            <v-col
                              v-else
                              class="no-breakdown-items"
                            >
                              Aún no has añadido metatags a esta charla
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="row-breakdown-items">
                    <v-col>
                      <v-row class="row-breakdown-items__title">
                        <v-col class="main">
                          <h3>
                            Objetivos
                            <v-icon dark>
                              {{ $vuetify.icons.values.tagPlus }}
                            </v-icon>
                          </h3>
                          <v-btn
                            color="info"
                            :small="$vuetify.breakpoint.xs"
                            @click="abrirCrearObjetivo()"
                          >
                            <v-icon>
                              {{ $vuetify.icons.values.plusBox }}
                            </v-icon>
                            Asociar nuevo Objetivo
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="row-breakdown-items__content">
                        <v-col class="col-12">
                          <v-row class="row-breakdown-items__form">
                            <v-col class="col-12 col-md-6 inputs">
                              <v-text-field
                                v-model="value.objetivoBox.Objetivo"
                                label="Name"
                                solo
                              />
                              <v-btn
                                v-if="value.objetivoModoCreacion === true"
                                color="info"
                                @click="crearObjetivo()"
                              >
                                Crear
                              </v-btn>
                              <v-btn
                                v-else
                                @click="editarObjetivo()"
                              >
                                editar
                              </v-btn>
                            </v-col>
                            <v-col class="col-12 col-md-6 alerts">
                              <div class="alert-main">
                                <v-alert
                                  v-if="showMsgObjetivos"
                                  dense
                                  text
                                  :type="typeMsgObjetivos"
                                >
                                  <span v-html="msgObjetivos" />
                                </v-alert>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            class="row-breakdown-items__table justify-center"
                          >
                            <v-col class="col-12">
                              <div class="divider" />
                            </v-col>
                            <v-col
                              v-if="
                                value.objetivos != undefined &&
                                  value.objetivos.length > 0
                              "
                              class="col-12 col-md-9 mt-5 mb-5 table-main"
                            >
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Objetivo
                                      </th>
                                      <th>
                                        Editar
                                      </th>
                                      <th>
                                        Eliminar
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in value.objetivos"
                                      :key="i"
                                    >
                                      <td>{{ item.Objetivo }}</td>
                                      <td class="td-btn">
                                        <v-btn
                                          color="info"
                                          small
                                          @click="
                                            abrirEditarObjetivo(
                                              value.objetivos.indexOf(
                                                item
                                              )
                                            )
                                          "
                                        >
                                          <v-icon>{{ $vuetify.icons.values.pencil }}</v-icon>
                                        </v-btn>
                                      </td>
                                      <td class="td-btn">
                                        <v-btn
                                          color="info"
                                          small
                                          @click="
                                            eliminarObjetivo(
                                              value.objetivos.indexOf(
                                                item
                                              )
                                            )
                                          "
                                        >
                                          <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                            <v-col
                              v-else
                              class="no-breakdown-items"
                            >
                              Aún no has añadido metatags a esta charla
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="row-breakdown-items">
                    <v-col>
                      <v-row class="row-breakdown-items__title">
                        <v-col class="main">
                          <h3>
                            Temas asociados
                            <v-icon dark>
                              {{ $vuetify.icons.values.clipboardTextMultiple }}
                            </v-icon>
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="row-breakdown-items__content">
                        <v-col class="col-12">
                          <v-row class="row-breakdown-items__form">
                            <v-col class="col-12 inputs">
                              <v-checkbox
                                v-for="(item, i) in value.temas"
                                :key="i"
                                v-model="item.checked"
                                :label="item.Descripcion"
                                color="info"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="darken-1"
                text
                @click="cerrar()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="info"
                default
                type="submit"
                :disabled="invalid"
                :loading="value.loader"
              >
                Grabar
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
  import { mapState } from 'vuex'
  import { VueEditor } from 'vue2-editor'
  export default {
    name: 'ShowDialogCharla',
    components: {
      VueEditor,
    },
    props: {
      value: {
        active: {
          type: Boolean,
          default: false,
        },
        charla: {
          type: Object,
          default: false,
        },
        imagen: {
          type: Object,
          default: false,
        },
        imagenMiniatura: {
          type: Object,
          default: false,
        },
        loader: {
          type: Boolean,
          default: false,
        },
        metaTagBox: {
          type: Object,
          default: {},
          name: {
            type: String,
            default: '',
          },
          content: {
            type: String,
            default: '',
          },
        },
        objetivoBox: {
          type: Object,
          default: {},
          Objetivo: {
            type: String,
            default: '',
          },
        },
        objetivoModoCreacion: {
          type: Boolean,
          default: true,
        },
        metaTagModoCreacion: {
          type: Boolean,
          default: true,
        },
        objetivos: {
          type: Array,
          default: false,
        },
        default: null,
        type: Object,
      },
    },
    data () {
      return {
        editorToolbarOptions: require('@/data/editorToolbarOptions.json'),
        metaTagIdEditar: false,
        objetivoIdEditar: false,
        msgMetaTag: '',
        showMsgMetaTag: false,
        typeMsgMetaTag: false,
        msgObjetivos: '',
        showMsgObjetivos: false,
        typeMsgObjetivos: false,
        timePicker: false,
        datePicker: false,
        ex4: [],
        porDefinir: false,
      }
    },
    computed: {
      ...mapState('web', ['sucursales', 'cldVersion']),
      lugares () {
        const lugares = []
        this.sucursales.map(suc => {
          const { Nombre } = suc
          lugares.push(Nombre)
        })
        lugares.push('Online', 'Por Definir')
        return lugares
      },
    },
    watch: {
      'value.active': function (value) {
        requestAnimationFrame(() => {
          // Reseteamos vee al cerrar dialog
          this.$refs.observer.reset()
        })
      },
      porDefinir: function (value) {
        if (value === true) {
          this.value.charla.Fecha = ''
        }
      },
    },
    methods: {
      validar () {
        this.$emit('procesar', this.value)
      },
      cerrar () {
        this.$emit('cerrar')
      },
      abrirEditarMetaTag (index) {
        console.log(`abrirEditar MetaTag ${index}`)
        this.metaTagIdEditar = index
        this.value.metaTagModoCreacion = false
        this.value.metaTagBox.name = this.value.charla.MetaTags[index].Name
        this.value.metaTagBox.content = this.value.charla.MetaTags[index].Content
      },
      abrirEditarObjetivo (index) {
        console.log(`abrirEditar Objetivo ${index}`)
        this.objetivoIdEditar = index
        this.value.objetivoModoCreacion = false
        this.value.objetivoBox.Objetivo = this.value.objetivos[index].Objetivo
      },
      abrirCrearMetaTag () {
        this.value.metaTagModoCreacion = true
        this.value.metaTagBox = {
          name: '',
          content: '',
        }
      },
      abrirCrearObjetivo () {
        this.value.objetivoModoCreacion = true
        this.value.objetivoBox = {
          Objetivo: '',
        }
      },
      crearMetaTag () {
        const element = {
          Name: this.value.metaTagBox.name,
          Content: this.value.metaTagBox.content,
        }
        if (!this.value.charla.MetaTags) this.value.charla.MetaTags = []
        this.value.charla.MetaTags.unshift(element)
        this.limpiaMetaTagBox()
        this.showAlertMetaTag(
          'Registro creado. Debe grabar para guardar cambios definitivamente',
          'success',
        )
      },
      async editarMetaTag () {
        this.value.charla.MetaTags[
          this.metaTagIdEditar
        ].Content = this.value.metaTagBox.content
        this.value.charla.MetaTags[
          this.metaTagIdEditar
        ].Name = this.value.metaTagBox.name
        this.limpiaMetaTagBox()
        this.showAlertMetaTag(
          'Registro editado. Debe grabar para guardar cambios definitivamente',
          'success',
        )
      },
      eliminarMetaTag (index) {
        if (confirm('¿Estás seguro/a que deseas eliminar este MetaTag?')) {
          this.value.charla.MetaTags.splice(index, 1)
          this.limpiaMetaTagBox()
          if (this.value.charla.MetaTags.length === 0) {
            this.value.charla.MetaTags = 0
          }
          this.showAlertMetaTag(
            'Registro eliminado. Debe grabar para guardar cambios definitivamente',
            'warning',
          )
        }
      },
      crearObjetivo () {
        const element = {
          Objetivo: this.value.objetivoBox.Objetivo,
        }
        if (!this.value.objetivos) this.value.objetivos = []
        this.value.objetivos.unshift(element)
        this.limpiaObjetivoBox()
        this.showAlertObjetivos(
          'Registro creado. Debe grabar para guardar cambios definitivamente',
          'success',
        )
      },
      async editarObjetivo () {
        console.log('this.objetivoIdEditar')
        console.log(this.objetivoIdEditar)
        this.value.objetivos[
          this.objetivoIdEditar
        ].Objetivo = this.value.objetivoBox.Objetivo
        this.limpiaObjetivoBox()
        this.showAlertObjetivos(
          'Registro editado. Debe grabar para guardar cambios definitivamente',
          'success',
        )
      },
      eliminarObjetivo (index) {
        if (confirm('¿Estás seguro/a que deseas eliminar este Objetivo?')) {
          this.value.objetivos.splice(index, 1)
          this.limpiaObjetivoBox()
          if (this.value.objetivos.length === 0) {
            this.value.objetivos = 0
          }

          this.showAlertObjetivos(
            'Registro eliminado. Debe grabar para guardar cambios definitivamente',
            'warning',
          )
        }
      },
      limpiaMetaTagBox () {
        this.value.metaTagModoCreacion = true
        this.value.metaTagBox.name = ''
        this.value.metaTagBox.content = ''
        this.metaTagIdEditar = false
      },
      limpiaObjetivoBox () {
        this.value.objetivoModoCreacion = true
        this.value.objetivoBox.Objetivo = ''
        this.objetivoIdEditar = false
      },
      showAlertMetaTag (msg, type) {
        this.msgMetaTag = msg
        this.typeMsgMetaTag = type
        this.showMsgMetaTag = true
        setTimeout(() => {
          this.msgMetaTag = ''
          this.typeMsgMetaTag = ''
          this.showMsgMetaTag = false
        }, 6000)
      },
      showAlertObjetivos (msg, type) {
        this.msgObjetivos = msg
        this.typeMsgObjetivos = type
        this.showMsgObjetivos = true
        setTimeout(() => {
          this.msgObjetivos = ''
          this.typeMsgObjetivos = ''
          this.showMsgObjetivos = false
        }, 6000)
      },
    },
  }
</script>

<style lang="sass" scoped>
@import '@/sass/variables2'

.pre-formatted
  white-space: pre

.validation-error-box
  border: 1px solid #ff6668

.validation-error-title
  color: #ff6668

.ql-editor
  height: 200px !important

.chk-charlas__main
  overflow-y: scroll
  height: 1220px

.show-dialog__charla
  background: red
.dialog-charla-card
  .dialog-charla-titulo
    h3
      color: #3a9cba !important
      display: inline
    span
      color: #9e9e9e
      text-transform: capitalize
      font-weight: bold
  .row-validations
    .row-validations__content
      background: $lightblack4
    .por-definir
      display: inline-block
      margin-top: 0px !important
    .row-validations__title
      .main
        background: #5bc0de
        color: white
        display: flex
        padding: 25px
        @media (max-width: 460px)
          justify-content: center

  .row-breakdown-items
    .row-breakdown-items__title
      .main
        background: #5bc0de
        color: white
        display: flex
        @media (max-width: 1264px)
          flex-direction: row
        @media (max-width: 460px)
          flex-direction: column
          justify-content: center
        h3
          display: flex
          justify-content: center
          align-items: center
          .v-icon
            margin-left: 5px !important
        .v-btn
          margin-right: 0px !important
    .row-breakdown-items__content
      background: $lightblack4
      .row-breakdown-items__form
        .inputs
          display: flex
          flex-direction: column
          justify-content: space-between
          padding: 20px
          .v-btn
            margin-right: 0px
            width: 200px
        .alerts
          display: flex
          flex-direction: column
          justify-content: space-between
          padding: 20px
          .alert-main
            height: 50px
          span
            font-weight: 300 !important
            font-size: 14px
          .v-btn
            margin-right: 0px
            width: 200px
      .row-breakdown-items__table
        .td-btn
          width: 50px
        .divider
          width: 100%
          height: 2px
          background: $lightblack3
        .table-main
          background: $white
        .no-breakdown-items
          text-align: center
          padding: 20px
          background: $lightblack4
</style>
