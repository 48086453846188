import jwt_decode from 'jwt-decode'
import router from '../router'

export default {
  obtenerUsuario (state, payload) {
    state.token = payload
    if (payload === '') {
      state.usuarioAdmin = ''
    } else {
      state.token = payload
      state.usuarioAdmin = jwt_decode(payload)
      router.push({ name: 'Admin' }) /* Lo enviamos al admin */
    }
  },
  cerrarSesion (state, payload) {
    localStorage.removeItem('token')
    state.token = false
    state.usuarioAdmin = ''
    router.push({ name: 'AdminLogin' })
  },
}
