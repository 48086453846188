import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/web/Index'),
      // redirect: 'home',
      children: [
        {
          name: 'Home',
          path: '',
          component: () => import('@/views/web/Home'),
        },
        {
          name: 'Noticias',
          path: '/noticias-psicologia',
          component: () => import('@/views/web/Noticias'),
        },
        {
          name: 'Noticia',
          path: '/noticias-psicologia/noticia/:id',
          component: () => import('@/views/web/Noticia'),
        },
        {
          name: 'Charlas y Talleres',
          path: '/charlas-talleres',
          component: () => import('@/views/web/CharlasTalleres'),
        },
        {
          name: 'Charla',
          path: '/charlas/charla/:toLink',
          component: () => import('@/views/web/Charla'),
        },
        {
          name: 'Unidad TEA',
          path: '/unidad-tea',
          component: () => import('@/views/web/UnidadTea'),
        },
        {
          name: 'Especialidades',
          path: '/especialidades/:toLink',
          component: () => import('@/views/web/Especialidad'),
        },
        {
          name: 'Problemas',
          path: '/problemas/:toLink',
          component: () => import('@/views/web/TipoProblema'),
        },
        {
          name: 'Problema',
          path: '/problema/:toLink',
          component: () => import('@/views/web/Problema'),
        },
        {
          name: 'Contactanos',
          path: '/contactanos',
          component: () => import('@/views/web/Contactanos'),
        },
        {
          name: 'Formulario Contactanos',
          path: '/contactanos/formulario/:sucursal',
          component: () => import('@/views/web/FormularioContactanos'),
        },
        {
          name: 'Orientación',
          path: '/orientacion-psicologica',
          component: () => import('@/views/web/OrientacionPsicologica'),
        },
        {
          name: 'Formulario Orientación',
          path: '/orientacion/formulario/:sucursal',
          component: () => import('@/views/web/FormularioOrientacion'),
        },
        {
          name: 'Trabajo',
          path: '/trabaja-con-nosotros',
          component: () => import('@/views/web/FormularioTrabajo'),
        },
        {
          name: 'Servicios Empresas',
          path: '/convenios-empresas',
          component: () => import('@/views/web/FormularioServiciosEmpresas'),
        },
        {
          name: 'Convenios',
          path: '/convenios-isapres-atencion-psicologica',
          component: () => import('@/views/web/ConveniosIsapres'),
        },
        {
          name: 'Aranceles',
          path: '/aranceles-psicologia',
          component: () => import('@/views/web/Aranceles'),
        },
        {
          name: 'Preguntas Frecuentes',
          path: '/preguntas-frecuentes-psicologia',
          component: () => import('@/views/web/PreguntasFrecuentes'),
        },
        {
          name: 'Comprometete Con Tu Terapia',
          path: '/comprometete-con-tu-terapia-psicologica',
          component: () => import('@/views/web/ComprometeteConTuTerapia'),
        },
        {
          name: 'Por Qué Consultar',
          path: '/por-que-consultar-al-psicologo',
          component: () => import('@/views/web/PorQueConsultar'),
        },
        {
          name: 'Cuestionario de Depresión',
          path: '/cuestionario-de-depresion',
          component: () => import('@/views/web/CuestionarioDepresion'),
        },
        {
          name: 'Nosotros',
          path: '/centro-de-salud-mental',
          component: () => import('@/views/web/Nosotros'),
        },
        {
          name: 'Beneficios y Descuentos',
          path: '/beneficios-y-descuentos-psicologia',
          component: () => import('@/views/web/BeneficiosDescuentos'),
        },
        {
          name: 'Sucursal',
          path: '/sucursales/:toLink',
          component: () => import('@/views/web/Sucursal'),
        },
        {
          name: 'Terapeutas',
          path: '/terapeutas/:sucursal?/:especialidad?/:nombre?',
          component: () => import('@/views/web/Terapeutas'),
        },
        {
          name: 'Terapia Individual y de Grupo',
          path: '/servicios/terapia-individual-y-de-grupo',
          component: () => import('@/views/web/servicios/TerapiaIndividualYDeGrupo'),
        },
        {
          name: 'Terapia Individual',
          path: '/servicios/terapia-individual/:toLink',
          component: () => import('@/views/web/servicios/Terapia'),
        },
        {
          name: 'Terapia Bilingue',
          path: '/servicios/terapia-bilingue',
          component: () => import('@/views/web/servicios/TerapiaBilingue'),
        },
        {
          name: 'Formulario Terapia Bilingue',
          path: '/servicios/formulario-terapia-bilingue',
          component: () =>
            import('@/views/web/servicios/FormularioTerapiaBilingue'),
          props: {
            sucursal: false,
            locale: false,
          },
        },
        {
          name: 'Evaluaciones',
          path: '/servicios/evaluaciones',
          component: () => import('@/views/web/servicios/Evaluaciones'),
        },
        {
          name: 'Evaluacion',
          path: '/servicios/evaluaciones/:toLink',
          component: () => import('@/views/web/servicios/Evaluacion'),
        },
        {
          name: 'ADOS-2',
          path: '/servicios/evaluaciones/evaluacion-neuropsicologica/ados-2',
          component: () => import('@/views/web/servicios/evaluacion/ADOS-2'),
        },
        {
          name: 'Tratamientos Médicos',
          path: '/servicios/tratamientos-medicos',
          component: () => import('@/views/web/servicios/TratamientosMedicos'),
        },
        {
          name: 'Terapias de Apoyo',
          path: '/servicios/terapias-de-apoyo',
          component: () => import('@/views/web/servicios/TerapiasDeApoyo'),
        },
        {
          name: 'Terapia Familiar',
          path: '/servicios/terapia-familiar',
          component: () => import('@/views/web/servicios/TerapiaFamiliar'),
        },
        {
          name: 'Terapia De Pareja',
          path: '/servicios/terapia-de-pareja',
          component: () => import('@/views/web/servicios/TerapiaDePareja'),
        },
        {
          name: 'Terapia Sexual',
          path: '/servicios/terapia-sexual',
          component: () => import('@/views/web/servicios/TerapiaSexual'),
        },
        /*
        Páginas SEO no visibles requeridas por Fabiola el 2022-02-28. Estos servicios no están en el menú y por tanto tampoco en la tabla Servicios.
        */
        {
          name: 'Psicologia Vina del Mar',
          path: '/psicologia-vina-del-mar',
          component: () => import('@/views/web/SEO_landings/EspecialidadPsicologiaVina'),
        },
        {
          name: 'TOL',
          path: '/tol',
          component: () => import('@/views/web/SEO_landings/ServicioTol'),
        },
        {
          name: 'Banmedica',
          path: '/psicologia/banmedica',
          component: () => import('@/views/web/SEO_landings/ServicioBanmedica'),
        },
        {
          name: 'Trastornos Sexuales',
          path: '/psicologia/trastornos-sexuales',
          component: () => import('@/views/web/SEO_landings/ServicioTrastornosSexuales'),
        },
        /*
        Fin SEO
        */
      ],
    },
    /*
      Páginas de aterrizaje solicitada por unidad comercial el 2023-07-24
    */
    {
      path: '/psicologos/',
      name: 'Psicologos',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Psicologos Santiago Centro',
          path: '/psicologos/psicologos-santiago-centro',
          component: () => import('@/components/web/landings/Psicologia.vue'),

        },
        {
          name: 'Psicologos Viña del Mar',
          path: '/psicologos/psicologos-vina-del-mar',
          component: () => import('@/components/web/landings/Psicologia.vue'),
        },
        {
          name: 'Psicologos La Dehesa',
          path: '/psicologos/psicologos-la-dehesa',
          component: () => import('@/components/web/landings/Psicologia.vue'),
        },
        {
          name: 'Psicologos Providencia',
          path: '/psicologos/psicologos-providencia',
          component: () => import('@/components/web/landings/Psicologia.vue'),
        },
      ],
    },
    {
      path: '/psicologos-infantiles/',
      name: 'Psicologos',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Psicologos Infantiles La Dehesa',
          path: '/psicologos-infantiles/psicologos-infantiles-la-dehesa',
          component: () => import('@/components/web/landings/PsicologiaInfantil.vue'),
        },
        {
          name: 'Psicologos Infantiles Santiago Centro',
          path: '/psicologos-infantiles/psicologos-infantiles-santiago-centro',
          component: () => import('@/components/web/landings/PsicologiaInfantil.vue'),
        },
        {
          name: 'Psicologos Infantiles Viña del Mar',
          path: '/psicologos-infantiles/psicologos-infantiles-vina-del-mar',
          component: () => import('@/components/web/landings/PsicologiaInfantil.vue'),
        },
        {
          name: 'Psicologos Infantiles Providencia',
          path: '/psicologos-infantiles/psicologos-infantiles-providencia',
          component: () => import('@/components/web/landings/PsicologiaInfantil.vue'),
        },
      ],
    },
    {
      path: '/psicologos-adolescentes/',
      name: 'Psicologos',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Psicologos Adolescentes La Dehesa',
          path: '/psicologos-adolescentes/psicologos-adolescentes-la-dehesa',
          component: () => import('@/components/web/landings/PsicologiaAdolescentes.vue'),
        },
        {
          name: 'Psicologos Adolescentes Santiago Centro',
          path: '/psicologos-adolescentes/psicologos-adolescentes-santiago-centro',
          component: () => import('@/components/web/landings/PsicologiaAdolescentes.vue'),
        },
        {
          name: 'Psicologos Adolescentes Viña del Mar',
          path: '/psicologos-adolescentes/psicologos-adolescentes-vina-del-mar',
          component: () => import('@/components/web/landings/PsicologiaAdolescentes.vue'),
        },
        {
          name: 'Psicologos Adolescentes Providencia',
          path: '/psicologos-adolescentes/psicologos-adolescentes-providencia',
          component: () => import('@/components/web/landings/PsicologiaAdolescentes.vue'),
        },
      ],
    },
    {
      path: '/psiquiatras/',
      name: 'Psiquiatras',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Psiquiatras Santiago Centro',
          path: '/psiquiatras/psiquiatras-santiago-centro',
          component: () => import('@/components/web/landings/Psiquiatria.vue'),
        },
        {
          name: 'Psiquiatras Viña del Mar',
          path: '/psiquiatras/psiquiatras-vina-del-mar',
          component: () => import('@/components/web/landings/Psiquiatria.vue'),
        },
        {
          name: 'Psiquiatras La Dehesa',
          path: '/psiquiatras/psiquiatras-la-dehesa',
          component: () => import('@/components/web/landings/Psiquiatria.vue'),
        },
        {
          name: 'Psiquiatras Providencia',
          path: '/psiquiatras/psiquiatras-providencia',
          component: () => import('@/components/web/landings/Psiquiatria.vue'),
        },
      ],
    },
    {
      path: '/neurologos-infantiles/',
      name: 'Neurologos',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Neurologos Infantiles La Dehesa',
          path: '/neurologos-infantiles/neurologos-infantiles-la-dehesa',
          component: () => import('@/components/web/landings/NeurologiaInfantil.vue'),
        },
        {
          name: 'Neurologos Infantiles Santiago Centro',
          path: '/neurologos-infantiles/neurologos-infantiles-santiago-centro',
          component: () => import('@/components/web/landings/NeurologiaInfantil.vue'),
        },
        {
          name: 'Neurologos Infantiles Providencia',
          path: '/neurologos-infantiles/neurologos-infantiles-providencia',
          component: () => import('@/components/web/landings/NeurologiaInfantil.vue'),
        },
        {
          name: 'Neurologos Infantiles Viña del Mar',
          path: '/neurologos-infantiles/neurologos-infantiles-vina-del-mar',
          component: () => import('@/components/web/landings/NeurologiaInfantil.vue'),
        },
      ],
    },
    {
      path: '/neurologos/',
      name: 'Neurologos',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Neurologos La Dehesa',
          path: '/neurologos/neurologos-la-dehesa',
          component: () => import('@/components/web/landings/Neurologia.vue'),
        },
        {
          name: 'Neurologos Santiago Centro',
          path: '/neurologos/neurologos-santiago-centro',
          component: () => import('@/components/web/landings/Neurologia.vue'),
        },
        {
          name: 'Neurologos Providencia',
          path: '/neurologos/neurologos-providencia',
          component: () => import('@/components/web/landings/Neurologia.vue'),
        },
        {
          name: 'Neurologos Viña del Mar',
          path: '/neurologos/neurologos-vina-del-mar',
          component: () => import('@/components/web/landings/Neurologia.vue'),
        },
      ],
    },
    {
      path: '/psiquiatras-infantiles/',
      name: 'PsiquiatrasInfantiles',
      component: () => import('@/views/web/IndexLandings'),
      children: [
        {
          name: 'Psiquiatras Infantiles La Dehesa',
          path: '/psiquiatras-infantiles/psiquiatras-infantiles-la-dehesa',
          component: () => import('@/components/web/landings/PsiquiatriaInfantil.vue'),
        },
        {
          name: 'Psiquiatras Infantiles Santiago Centro',
          path: '/psiquiatras-infantiles/psiquiatras-infantiles-santiago-centro',
          component: () => import('@/components/web/landings/PsiquiatriaInfantil.vue'),
        },
        {
          name: 'Psiquiatras Infantiles Viña del Mar',
          path: '/psiquiatras-infantiles/psiquiatras-infantiles-vina-del-mar',
          component: () => import('@/components/web/landings/PsiquiatriaInfantil.vue'),
        },
        {
          name: 'Psiquiatras Infantiles Providencia',
          path: '/psiquiatras-infantiles/psiquiatras-infantiles-providencia',
          component: () => import('@/components/web/landings/PsiquiatriaInfantil.vue'),
        },
      ],
    },
    /*
      Fin Páginas
    */
    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: () => import('@/views/web/AdminLogin'),
    },
    {
      path: '/admin',
      component: () => import('@/views/admin/Index'),

      children: [
        {
          name: 'Admin',
          path: '',
          component: () => import('@/views/admin/Dashboard'),
        },
        // ****************** ORIGINALES DASHBOARD ***************************
        // Dashboard
        {
          name: 'Sliders Principal',
          path: 'sliders/principal',
          component: () => import('@/views/admin/terapia/SlidersPrincipal'),
        },
        {
          name: 'Sliders Bienvenidos',
          path: 'sliders/bienvenidos',
          component: () => import('@/views/admin/terapia/SlidersBienvenidos'),
        },
        {
          name: 'Sliders Empresas',
          path: 'sliders/empresas',
          component: () => import('@/views/admin/terapia/SlidersEmpresas'),
        },
        {
          name: 'Tipos de Problema',
          path: 'problemas-y-especialidades/problemas-tipos',
          component: () => import('@/views/admin/terapia/ProblemasTipos'),
        },
        {
          name: 'Admin Problemas',
          path: 'problemas-y-especialidades/problemas',
          component: () => import('@/views/admin/terapia/Problemas'),
        },
        {
          name: 'Admin Noticias',
          path: 'pagina/noticias',
          component: () => import('@/views/admin/terapia/Noticias'),
        },
        {
          name: 'Usuarios',
          path: 'pagina/usuarios',
          component: () => import('@/views/admin/terapia/Usuarios'),
        },
        {
          name: 'Charlas',
          path: 'charlas-y-talleres/charlas',
          component: () => import('@/views/admin/terapia/Charlas'),
        },
        {
          name: 'Acceso Reserva Landing',
          path: 'estadisticas/landing-reserva',
          component: () => import('@/views/admin/terapia/EstadisticasLanding'),
        },
        {
          name: 'Mensaje Sitio',
          path: 'mensaje-sitio',
          component: () => import('@/views/admin/terapia/MensajeSitio'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

function loadRecaptcha() {
  return new Promise((resolve) => {
    if (window.grecaptcha) {
      console.log("✅ reCAPTCHA ya está cargado.");
      return resolve();
    }

    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      console.log("✅ reCAPTCHA cargado correctamente.");
      resolve();
    };

    document.head.appendChild(script);
  });
}

router.beforeResolve(async (to, from, next) => {
  if (
    to.path.includes('/psicologos/psicologos') ||
    to.path.includes('/psicologos-infantiles/psicologos-infantiles') ||
    to.path.includes('/psicologos-adolescentes/psicologos-adolescentes') ||
    to.path.includes('/psiquiatras/psiquiatras') ||
    to.path.includes('/psiquiatras-infantiles/psiquiatras-infantiles') ||
    to.path.includes('/neurologos/neurologos') ||
    to.path.includes('/neurologos-infantiles/neurologos-infantiles') ||
    to.path.includes('/unidad-tea') ||
    to.path.includes('formulario') ||
    to.name.includes('Trabajo') ||
    to.name.includes('Charla')
  ) {
    console.log("🔄 Cargando reCAPTCHA...");
    await loadRecaptcha();
  }
  next();
});

// Control login Admin Terapia
router.beforeEach((to, from, next) => {
  const estaActivo = store.getters.estaActivo
  if (
    to.path.indexOf('/admin', 0) !== -1 &&
    to.path.indexOf('/admin/login', 0) === -1
  ) {
    if (estaActivo) {
      next()
    } else {
      next({
        path: '/admin/login',
      })
    }
  } else if (to.path.indexOf('/admin/login', 0) !== -1) {
    if (estaActivo) {
      next({
        path: '/admin',
      })
    } else {
      next()
    }
  } else {
    next()
  }

  window.scrollTo(0, 0) // Para que muestre siempre el scroll on top
})

export default router
