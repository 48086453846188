import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";
import "@/assets/css/animations.css";

// ✅ Importar SOLO los iconos necesarios desde `@mdi/js`
import {
  mdiMapMarker,
  mdiAccount,
  mdiHome,
  mdiClose,
  mdiMenuDown,
  mdiPhone,
  mdiWhatsapp,
  mdiCalendarMonthOutline,
  mdiCastConnected,
  mdiInstagram,
  mdiFacebook,
  mdiLinkedin,
  mdiTwitter,
  mdiMenuUp,
  mdiChevronLeft,
  mdiChevronRight,
  mdiPlay,
  mdiPause,
  mdiEmoticonWinkOutline,
  mdiTagPlus,
  mdiPlusBox,
  mdiPencil,
  mdiDelete,
  mdiClipboardTextMultiple,
  mdiFormatQuoteOpen,
  mdiCircleSmall,
  mdiToolbox,
  mdiAtomVariant,
  mdiForum,
  mdiLogin,
  mdiAccountGroup,
  mdiEmailOutline,
  mdiCalendarMonth,
  mdiBug,
  mdiCodeTags,
  mdiCloud,
  mdiHeart,
  mdiMaterialDesign,
  mdiViewQuilt,
  mdiDotsVertical,
  mdiSettingsHelper,
  mdiCheck,
  mdiPost,
  mdiTrashCan,
  mdiCheckBold,
  mdiEarth,
  mdiMenu,
  mdiMenuRight
} from "@mdi/js";

Vue.use(Vuetify);

const theme = {
  primary: "#E91E63",
  secondary: "#9C27b0",
  accent: "#9C27b0",
  info: "#3a9cba",
  warning: "#f88c00",
  verde: "#36ba7f",
  blanco: "#ffffff",
  gris: "#595959",
  lightgreen4: "#e9f2ee",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    iconfont: "mdiSvg", // ✅ IMPORTANTE: Usar "mdiSvg" en lugar de "mdi"
    values: {
      mapMarker: mdiMapMarker, // ✅ Asignar el path correcto
      account: mdiAccount,
      home: mdiHome,
      close: mdiClose,
      menuDown: mdiMenuDown,
      phone: mdiPhone,
      whatsApp: mdiWhatsapp,
      calendarMonthOutline: mdiCalendarMonthOutline,
      castConnected: mdiCastConnected,
      instagram: mdiInstagram,
      facebook: mdiFacebook,
      twitter: mdiTwitter,
      linkedin: mdiLinkedin,
      menuUp: mdiMenuUp,
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
      play: mdiPlay,
      pause: mdiPause,
      emoticonWinkOutline: mdiEmoticonWinkOutline,
      tagPlus: mdiTagPlus,
      plusBox: mdiPlusBox,
      pencil: mdiPencil,
      delete: mdiDelete,
      clipboardTextMultiple: mdiClipboardTextMultiple,
      formatQuoteOpen: mdiFormatQuoteOpen,
      circleSmall: mdiCircleSmall,
      toolbox: mdiToolbox,
      atomVariant: mdiAtomVariant,
      forum: mdiForum,
      login: mdiLogin,
      accountGroup: mdiAccountGroup,
      emailOutline: mdiEmailOutline,
      calendarMonth: mdiCalendarMonth,
      bug: mdiBug,
      codeTags: mdiCodeTags,
      cloud: mdiCloud,
      heart: mdiHeart,
      materialDesign: mdiMaterialDesign,
      viewQuilt: mdiViewQuilt,
      dotsVertical: mdiDotsVertical,
      settingsHelper: mdiSettingsHelper,
      check: mdiCheck,
      post: mdiPost,
      trashCan: mdiTrashCan,
      checkBold: mdiCheckBold,
      earth: mdiEarth,
      menu: mdiMenu,
      menuRight: mdiMenuRight
    },
  },
});
