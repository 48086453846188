import { render, staticRenderFns } from "./ShowDialogSliderPrincipal.vue?vue&type=template&id=81eafdfc&scoped=true"
import script from "./ShowDialogSliderPrincipal.vue?vue&type=script&lang=js"
export * from "./ShowDialogSliderPrincipal.vue?vue&type=script&lang=js"
import style0 from "./ShowDialogSliderPrincipal.vue?vue&type=style&index=0&id=81eafdfc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81eafdfc",
  null
  
)

export default component.exports