<template>
  <div class="text-center show-dialog__noticia">
    <v-dialog
      v-model="value.active"
      max-width="1400"
      persistent
      scrollable
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="validar()">
          <v-card class="dialog-noticia-card">
            <v-card-title class="dialog-noticia-titulo text-h5">
              <h3>
                {{ value.noticia.Id ? `Editando: ` : 'Nueva Noticia' }}
              </h3>
              <span
                v-if="value.noticia.Titulo"
                v-html="value.noticia.Titulo"
              />
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-12">
                  <v-row class="row-validations">
                    <v-col class="col-12">
                      <v-row class="row-validations__title">
                        <v-col class="main">
                          <h3>Información</h3>
                        </v-col>
                      </v-row>
                      <v-row class="row-validations__content">
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:100"
                            name="Título"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Título
                                </label>
                              </template>
                              <span>Título de la noticia</span>
                            </v-tooltip>
                            <v-text-field
                              v-model="value.noticia.Titulo"
                              :error-messages="errors"
                              color="success"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:250"
                            name="Meta Title"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Meta Title
                                </label>
                              </template>
                              <span>Título a mostrar en pestaña (considerar
                                SEO)</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.noticia.MetaTitle"
                              :error-messages="errors"
                              color="success"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Archivo"
                            rules="ext:jpg|size:1000"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text label-noticia"
                                  v-on="on"
                                  v-html="
                                    value.noticia.Imagen
                                      ? `Cambiar Imagen (Ver imagen actual: <a style='color: orange !important;' href='https://res.cloudinary.com/dkod5pdlc/image/upload/v${cldVersion}/terapiacl/blog/${value.noticia.Imagen}' target='_blank'>${value.noticia.Imagen}</a>)`
                                      : 'Subir Imagen'
                                  "
                                />
                              </template>
                              <span>Cambia la imagen actual asociada a la
                                noticia</span>
                            </v-tooltip>
                            <v-file-input
                              v-model="value.imagen"
                              small-chips
                              :error-messages="errors"
                              label="Formato: jpg, (1200x400px sugeridos)"
                              dense
                              filled
                              color="success"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-md-6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:250"
                            name="Usuario"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <label
                                  v-bind="attrs"
                                  class="grey--text"
                                  v-on="on"
                                >
                                  Usuario
                                </label>
                              </template>
                              <span>Datos del usuario que creó la noticia</span>
                            </v-tooltip>

                            <v-text-field
                              v-model="value.noticia.Usuario"
                              :error-messages="errors"
                              color="success"
                              type="text"
                              filled
                              dense
                            />
                          </validation-provider>
                        </v-col>
                        <v-col class="col-12">
                          <h4>Texto</h4>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:8000"
                            name="Texto"
                          >
                            <vue-editor
                              v-model="value.noticia.Texto"
                              :error-messages="errors"
                              :editor-toolbar="editorToolbarOptions"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="row-metatags">
                    <v-col>
                      <v-row class="row-metatags__title">
                        <v-col class="main">
                          <h3>
                            Meta Tags
                            <v-icon dark>
                              {{ $vuetify.icons.values.tagPlus }}
                            </v-icon>
                          </h3>
                          <v-btn
                            color="info"
                            :small="$vuetify.breakpoint.xs"
                            @click="abrirCrearMetaTag()"
                          >
                            <v-icon>
                              {{ $vuetify.icons.values.plusBox }}
                            </v-icon>
                            Crear Nuevo MetaTag
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="row-metatags__content">
                        <v-col class="col-12">
                          <v-row class="metatags__form">
                            <v-col class="col-12 col-md-6 inputs">
                              <v-text-field
                                v-model="value.metaTagBox.name"
                                label="Name"
                                solo
                              />
                              <v-text-field
                                v-model="value.metaTagBox.content"
                                label="Content"
                                solo
                              />
                              <v-btn
                                v-if="value.crear"
                                color="info"
                                @click="crearMetaTag()"
                              >
                                Crear
                              </v-btn>
                              <v-btn
                                v-else
                                @click="editarMetaTag()"
                              >
                                editar
                              </v-btn>
                            </v-col>
                            <v-col class="col-12 col-md-6 alerts">
                              <div class="alert-main">
                                <v-alert
                                  v-if="showMsg"
                                  dense
                                  text
                                  :type="typeMsg"
                                >
                                  <span v-html="msg" />
                                </v-alert>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="metatags__table justify-center">
                            <v-col class="col-12">
                              <div class="divider" />
                            </v-col>
                            <v-col
                              v-if="
                                value.noticia.MetaTags != undefined &&
                                  value.noticia.MetaTags.length > 0
                              "
                              class="col-12 col-md-9 mt-5 mb-5 table-main"
                            >
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Name
                                      </th>
                                      <th class="text-left">
                                        Content
                                      </th>
                                      <th>
                                        Editar
                                      </th>
                                      <th>
                                        Eliminar
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in value.noticia
                                        .MetaTags"
                                      :key="i"
                                    >
                                      <td>{{ item.Name }}</td>
                                      <td>{{ item.Content }}</td>
                                      <td class="td-btn">
                                        <v-btn
                                          color="info"
                                          small
                                          @click="
                                            abrirEditarMetaTag(
                                              value.noticia.MetaTags.indexOf(
                                                item
                                              )
                                            )
                                          "
                                        >
                                          <v-icon>{{ $vuetify.icons.values.pencil }}</v-icon>
                                        </v-btn>
                                      </td>
                                      <td class="td-btn">
                                        <v-btn
                                          color="info"
                                          small
                                          @click="
                                            eliminarMetaTag(
                                              value.noticia.MetaTags.indexOf(
                                                item
                                              )
                                            )
                                          "
                                        >
                                          <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                            <v-col
                              v-else
                              class="sin-metatags"
                            >
                              Aún no has añadido metatags a esta noticia
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="darken-1"
                text
                @click="cerrar()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="info"
                default
                type="submit"
                :disabled="invalid"
                :loading="value.loader"
              >
                Grabar
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
  import { mapState } from 'vuex'
  import { VueEditor } from 'vue2-editor'
  export default {
    name: 'ShowDialogNoticia',
    components: {
      VueEditor,
    },
    props: {
      value: {
        active: {
          type: Boolean,
          default: false,
        },
        noticia: {
          type: Object,
          default: false,
        },
        imagen: {
          type: Object,
          default: false,
        },
        loader: {
          type: Boolean,
          default: false,
        },
        metaTagBox: {
          type: Object,
          default: {},
          name: {
            type: String,
            default: '',
          },
          content: {
            type: String,
            default: '',
          },
          crear: {
            type: Boolean,
            default: true,
          },
        },
        default: null,
        type: Object,
      },
    },
    data () {
      return {
        editorToolbarOptions: require('@/data/editorToolbarOptions.json'),
        currentIndex: false,
        msg: '',
        showMsg: false,
        typeMsg: false,
      }
    },
    computed: {
      ...mapState('web', ['cldVersion']),
    },
    watch: {
      'value.active': function (value) {
        requestAnimationFrame(() => {
          // Reseteamos vee al cerrar dialog
          this.$refs.observer.reset()
        })
      },
    },
    methods: {
      validar () {
        this.$emit('procesar', this.value)
      },
      cerrar () {
        this.$emit('cerrar')
      },
      abrirEditarMetaTag (index) {
        // console.log(`abrirEditar MetaTag ${index}`)
        this.currentIndex = index
        this.value.crear = false
        this.value.metaTagBox.name = this.value.noticia.MetaTags[index].Name
        this.value.metaTagBox.content = this.value.noticia.MetaTags[index].Content
      },
      abrirCrearMetaTag () {
        this.value.crear = true
        this.value.metaTagBox = {
          name: '',
          content: '',
        }
      },
      crearMetaTag () {
        const element = {
          Name: this.value.metaTagBox.name,
          Content: this.value.metaTagBox.content,
        }
        if (!this.value.noticia.MetaTags) this.value.noticia.MetaTags = []
        this.value.noticia.MetaTags.unshift(element)
        this.limpiaMetaTagBox()
        this.showAlert(
          'Registro creado. Debe grabar para guardar cambios definitivamente',
          'success',
        )
      },
      async editarMetaTag () {
        this.value.noticia.MetaTags[
          this.currentIndex
        ].Content = this.value.metaTagBox.content
        this.value.noticia.MetaTags[
          this.currentIndex
        ].Name = this.value.metaTagBox.name
        this.limpiaMetaTagBox()
        this.showAlert(
          'Registro editado. Debe grabar para guardar cambios definitivamente',
          'success',
        )
      },
      eliminarMetaTag (index) {
        if (confirm('¿Estás seguro/a que deseas eliminar este MetaTag?')) {
          this.value.noticia.MetaTags.splice(index, 1)
          if (this.value.noticia.MetaTags.length === 0) {
            this.value.noticia.MetaTags = 0
          }
          this.showAlert(
            'Registro eliminado. Debe grabar para guardar cambios definitivamente',
            'warning',
          )
        }
      },
      limpiaMetaTagBox () {
        this.value.crear = true
        this.value.metaTagBox.name = ''
        this.value.metaTagBox.content = ''
        this.currentIndex = false
      },
      showAlert (msg, typeMsg) {
        this.msg = msg
        this.typeMsg = typeMsg
        this.showMsg = true
        setTimeout(() => {
          this.msg = ''
          this.typeMsg = ''
          this.showMsg = false
        }, 6000)
      },
    },
  }
</script>

<style lang="sass" scoped>
@import '@/sass/variables2'

.pre-formatted
  white-space: pre

.validation-error-box
  border: 1px solid #ff6668

.validation-error-title
  color: #ff6668

.ql-editor
  height: 200px !important

.chk-noticias__main
  overflow-y: scroll
  height: 1220px

.show-dialog__noticia
  // background: red
.dialog-noticia-card
  .dialog-noticia-titulo
    h3
      color: #3a9cba !important
      display: inline
    span
      color: #9e9e9e
      text-transform: capitalize
      font-weight: bold
  .row-validations
    .row-validations__content
      background: $lightblack4
    .row-validations__title
      .main
        background: #5bc0de
        color: white
        display: flex
        padding: 25px
        @media (max-width: 460px)
          justify-content: center

  .row-metatags
    .row-metatags__title
      .main
        background: #5bc0de
        color: white
        display: flex
        @media (max-width: 1264px)
          flex-direction: row
        @media (max-width: 460px)
          flex-direction: column
          justify-content: center
        h3
          display: flex
          justify-content: center
          align-items: center
          .v-icon
            margin-left: 5px !important
        .v-btn
          margin-right: 0px !important
    .row-metatags__content
      background: $lightblack4
      .metatags__form
        .inputs
          display: flex
          flex-direction: column
          justify-content: space-between
          padding: 20px
          .v-btn
            margin-right: 0px
            width: 200px
        .alerts
          display: flex
          flex-direction: column
          justify-content: space-between
          padding: 20px
          .alert-main
            height: 50px
          span
            font-weight: 300 !important
            font-size: 14px
          .v-btn
            margin-right: 0px
            width: 200px
      .metatags__table
        .td-btn
          width: 50px
        .divider
          width: 100%
          height: 2px
          background: $lightblack3
        .table-main
          background: $white
        .sin-metatags
          text-align: center
          padding: 20px
          background: $lightblack4
</style>
