import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  localize,
} from 'vee-validate'
import { rutValidator, rutFilter, rutInputDirective } from 'vue-dni'

import { email, ext, max, min, numeric, required, size, excluded, alpha_dash } from 'vee-validate/dist/rules'

// Configuramos el i18n de vuelidate a español
import es from 'vee-validate/dist/locale/es.json'
Vue.directive('rut', rutInputDirective)
Vue.filter('rutFilter', rutFilter)
localize('es', es)

// Rules
extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)
extend('rut', rutValidator)
extend('ext', ext)
extend('size', size)
extend('excluded', excluded)
extend('alpha_dash', alpha_dash)

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
