export default {
  // categories: state => {
  //   const categories = []

  //   for (const article of state.articles) {
  //     if (
  //       !article.category ||
  //       categories.find(category => category.text === article.category)
  //     ) {
  //       continue
  //     } // pasa a la siguiente iteracion

  //     const text = article.category

  //       categories.push({
  //           text,
  //           href: '#!',
  //       })
  //   }

  //   return categories.sort().slice(0, 4)
  // },
  estaActivo: state => !!state.token,
  setDatosPerfil: state => {
    const nombre = state.usuarioAdmin.data[0].Nombre.split(' ').slice(0, 1).join(' ')
    const correo = state.usuarioAdmin.data[0].Email
    const imagen = state.usuarioAdmin.data[0].Imagen

    state.datosPerfil = {
      nombre,
      correo,
      imagen,
    }
  },
}
