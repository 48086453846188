<template>
  <div class="text-center">
    <v-dialog
      v-model="value.active"
      max-width="1400"
      persistent
      scrollable
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="validar()">
          <v-card>
            <v-card-title class="dialog-usuario-titulo text-h5">
              <h3>
                {{ value.usuario.CodUsuario ? `Editando: ` : 'Nuevo usuario' }}
              </h3>
              <span
                v-if="value.usuario.CodUsuario"
                v-html="value.usuario.Nombre"
              />
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-12">
                  <v-row>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="El usuario debe incluir un nombre"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Nombre
                            </label>
                          </template>
                          <span>Nombre completo del usuario</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.usuario.Nombre"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:50|email"
                        name="El usuario debe incluir un email"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Email del Usuario
                            </label>
                          </template>
                          <span>Email del Usuario</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.usuario.Email"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Debe seleccionar un Tipo de Usuario"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="label-img"
                              v-on="on"
                            >Tipo de Usuario</label>
                          </template>
                          <span>Tipo de usuario. Necesario para delimitar el rango de acciones</span>
                        </v-tooltip>
                        <v-select
                          v-model="value.usuario.TipoUsuario"
                          :items="value.usuariosTipos"
                          :error-messages="errors"
                          filled
                          dense
                          item-value="CodTipoUsuario"
                          item-text="Descripcion"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="(value.usuario.CodUsuario) ? `max:20` : `required|max:20`"
                        name="Debe seleccionar Password"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="label-img"
                              v-on="on"
                              v-html="(value.usuario.CodUsuario) ? `Cambiar Password`: `Password`"
                            />
                          </template>
                          <span>Cambia el password actual del usuario</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.usuario.Password"
                          :error-messages="errors"
                          color="success"
                          type="password"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Debe seleccionar un género"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="label-img"
                              v-on="on"
                            >Género</label>
                          </template>
                          <span>Género del usuario</span>
                        </v-tooltip>
                        <v-select
                          v-model="value.usuario.Sexo"
                          :items="sexos"
                          :error-messages="errors"
                          filled
                          dense
                          item-value="id"
                          item-text="descripcion"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Archivo"
                        rules="ext:jpg|size:1000"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                              v-html="
                                value.usuario.Imagen
                                  ? 'Cambiar Imagen'
                                  : 'Subir Imagen'
                              "
                            />
                          </template>
                          <span>Cambia la imagen actual asociada al usuario</span>
                        </v-tooltip>
                        <v-file-input
                          v-model="value.imagen"
                          small-chips
                          :error-messages="errors"
                          label="Formato: jpg, (1200x400px sugeridos)"
                          dense
                          filled
                          color="success"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="cerrar()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="success"
                default
                type="submit"
                :disabled="invalid"
                :loading="value.loader"
              >
                Grabar
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
  export default {
    name: 'ShowDialogUsuario',
    props: {
      value: {
        active: {
          type: Boolean,
          default: false,
        },
        usuario: {
          type: Object,
          default: false,
          password: {
            type: Text,
            default: false,
          },
        },
        imagen: {
          type: Object,
          default: false,
        },
        loader: {
          type: Boolean,
          default: false,
        },
        usuariosTipos: {
          type: Array,
          default: [],
        },
        default: null,
        type: Object,
      },
    },
    data: () => ({
      sexos: [
        { id: 0, descripcion: 'Femenino' },
        { id: 1, descripcion: 'Masculino' },
      ],
    }),
    watch: {
      'value.active': function (value) {
        requestAnimationFrame(() => {
          // Reseteamos vee al cerrar dialog
          this.$refs.observer.reset()
        })
      },
    },
    methods: {
      validar () {
        this.$emit('procesar', this.value)
      },
      cerrar () {
        this.$emit('cerrar')
      },
    },
  }
</script>

<style lang="sass" scoped>
@import '@/sass/variables2'

.pre-formatted
  white-space: pre

.validation-error-box
  border: 1px solid #ff6668

.validation-error-title
  color: #ff6668

.ql-editor
  height: 200px !important

.chk-usuarios__main
  overflow-y: scroll
  height: 1220px

  .label-img
  color: #9e9e9e
  span
    color: $orange

.label-img
  color: #9e9e9e
  span
    color: $orange
.v-card
  .dialog-usuario-titulo
    h3
      color: $green !important
      display: inline
    span
      color: #9e9e9e
      text-transform: capitalize
      font-weight: bold
.label-span
  font-size: 13px !important
  color: $green
</style>
