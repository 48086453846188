<template>
  <div class="text-center">
    <v-dialog
      v-model="value.active"
      max-width="1400"
      persistent
      scrollable
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="validar()">
          <v-card>
            <v-card-title class="text-h5 dialog-slider-titulo">
              <h3>
                {{ value.slider.Id ? `Editando Slider ` : 'Nuevo Slider' }}
              </h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:100"
                    name="URL Externa"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <label
                          v-bind="attrs"
                          class="grey--text"
                          v-on="on"
                        >
                          URL Externa
                        </label>
                      </template>
                      <span>Puedes asignar una URL externa para que abra (en una
                        pestaña aparte) al pinchar slider. Ej:
                        https://www.terapiaonline.cl/</span>
                    </v-tooltip>
                    <v-text-field
                      v-model="value.slider.HrefLink"
                      :error-messages="errors"
                      color="success"
                      type="text"
                      filled
                      dense
                    />
                  </validation-provider>
                </v-col>
                <v-col class="col-12 col-sm-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:50"
                    name="Máximo 50 caracteres"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <label
                          v-bind="attrs"
                          class="grey--text"
                          v-on="on"
                        >
                          URL Interna
                        </label>
                      </template>
                      <span>Puedes asignar una URL interna para que abra al pinchar
                        el slider. Ej: /especialidades/psiquiatria-adulto</span>
                    </v-tooltip>

                    <v-text-field
                      v-model="value.slider.ToLink"
                      :error-messages="errors"
                      color="success"
                      type="text"
                      filled
                      dense
                    />
                  </validation-provider>
                </v-col>
                <v-col class="col-12 col-sm-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|numeric|max:2"
                    name="Orden"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <label
                          v-bind="attrs"
                          class="grey--text"
                          v-on="on"
                        >
                          Orden
                        </label>
                      </template>
                      <span>Indica el orden que tendrá el slider al ser desplegado
                        (de menor a mayor)</span>
                    </v-tooltip>
                    <v-text-field
                      v-model="value.slider.Orden"
                      :error-messages="errors"
                      color="success"
                      type="text"
                      filled
                      dense
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="row__imagenes">
                <v-col class="col-12 col-sm-6 row_imagenes__main">
                  <span
                    v-if="value.slider.Imagen"
                    class="img-title-span"
                  >
                    Imagen Desktop actual:
                    <a
                      :href="
                        `https://res.cloudinary.com/dkod5pdlc/image/upload/v${cldVersion}/terapiacl/sliders/bienvenidos/${value.slider.Imagen}`
                      "
                      target="_blank"
                      v-html="value.slider.Imagen"
                    />
                  </span>
                  <span
                    v-else
                    class="img-title-span"
                    v-html="'Imagen Desktop:'"
                  />

                  <v-checkbox
                    v-model="value.chkImagen"
                    :label="value.slider.Imagen ? 'Cambiar' : 'Subir'"
                  />
                  <div
                    v-if="value.chkImagen"
                    class="img-box"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Archivo"
                      rules="required|ext:jpg,png"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="grey--text"
                            v-on="on"
                            v-html="'Archivo'"
                          />
                        </template>
                        <span>Cambia la imagen actual asociada al Slider</span>
                      </v-tooltip>
                      <v-file-input
                        v-model="value.imagen"
                        small-chips
                        :error-messages="errors"
                        label="Formato: jpg, png (1200x400px sugeridos)"
                        dense
                        filled
                        color="success"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nombre Imagen"
                      rules="required|max:50"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="grey--text"
                            v-on="on"
                          >
                            Nombre
                          </label>
                        </template>
                        <span>El nombre con que se guardará la imagen. Si subes
                          una, es necesario establecerlo ya que promueve un SEO
                          correcto</span>
                      </v-tooltip>
                      <v-text-field
                        v-model="value.nombreImagen"
                        :error-messages="errors"
                        color="success"
                        type="text"
                        filled
                        dense
                      />
                    </validation-provider>
                  </div>
                </v-col>
                <v-col class="col-12 col-sm-6 row_imagenes__main">
                  <span
                    v-if="value.slider.ImagenMobile"
                    class="img-title-span"
                  >
                    Imagen Mobile actual:
                    <a
                      :href="
                        `https://res.cloudinary.com/dkod5pdlc/image/upload/v${cldVersion}/terapiacl/sliders-mobile/bienvenidos/${value.slider.ImagenMobile}`
                      "
                      target="_blank"
                      v-html="value.slider.ImagenMobile"
                    />
                  </span>
                  <span
                    v-else
                    class="img-title-span"
                    v-html="'Imagen Mobile:'"
                  />
                  <v-checkbox
                    v-model="value.chkImagenMobile"
                    :label="value.slider.ImagenMobile ? 'Cambiar' : 'Subir'"
                  />
                  <div
                    v-if="value.chkImagenMobile"
                    class="img-box"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Archivo"
                      rules="required|ext:jpg,png"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="grey--text"
                            v-on="on"
                            v-html="'Archivo'"
                          />
                        </template>
                        <span>Cambia la imagen actual para dispositivos pequeños
                          asociada al Slider. Generalmente esta imagen es de
                          menor longitud</span>
                      </v-tooltip>
                      <v-file-input
                        v-model="value.imagenMobile"
                        small-chips
                        :error-messages="errors"
                        label="Formato: jpg, png (400x200px sugeridos)"
                        dense
                        filled
                        color="success"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nombre Imagen"
                      rules="required|max:50"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="grey--text"
                            v-on="on"
                          >
                            Nombre
                          </label>
                        </template>
                        <span>El nombre con que se guardará la imagen. Si subes
                          una, es necesario establecerlo ya que promueve un SEO
                          correcto</span>
                      </v-tooltip>
                      <v-text-field
                        v-model="value.nombreImagenMobile"
                        :error-messages="errors"
                        color="success"
                        type="text"
                        filled
                        dense
                      />
                    </validation-provider>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="cerrar()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="success"
                default
                type="submit"
                :disabled="invalid"
                :loading="value.loader"
              >
                Grabar
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'ShowDialogSliderBienvenidos',
    props: {
      value: {
        slider: {
          type: Object,
          default: false,
        },
        imagen: {
          type: Object,
          default: false,
        },
        imagenMobile: {
          type: Object,
          default: false,
        },
        nombreImagen: {
          type: String,
          default: '',
        },
        nombreImagenMobile: {
          type: String,
          default: '',
        },
        chkImagen: {
          type: Boolean,
          default: false,
        },
        chkImagenMobile: {
          type: Boolean,
          default: false,
        },
        loader: {
          type: Boolean,
          default: false,
        },
        active: {
          type: Boolean,
          default: false,
        },
        default: null,
        type: Object,
      },
    },
    computed: {
      ...mapState('web', ['cldVersion']),
    },
    watch: {
      'value.active': function (value) {
        requestAnimationFrame(() => {
          // Reseteamos vee al cerrar dialog
          this.$refs.observer.reset()
        })
      },
    },
    methods: {
      validar () {
        this.$emit('procesar', this.value)
      },
      cerrar () {
        this.$emit('cerrar')
      },
    },
  }
</script>

<style lang="sass" scoped>
@import '@/sass/variables2'
.v-card
  .dialog-slider-titulo
    h3
      color: $green !important
      display: inline
    span
      color: #9e9e9e
      text-transform: capitalize
      font-weight: bold

.row__imagenes
  color: $lightblack2
  border-radius: 10px
  .row_imagenes__main
    .img-title-span
      font-weight: 600
      a
        color: $orange !important
        font-weight: 400
    .img-box
      background: $lightblack4
      padding: 20px
      border-radius: 10px
</style>
