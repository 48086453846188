<template>
  <div class="text-center">
    <v-dialog
      v-model="value.active"
      max-width="1400"
      persistent
      scrollable
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="validar()">
          <v-card>
            <v-card-title class="text-h5 dialog-problema-titulo">
              <h3>
                {{
                  value.tipoProblema.CodTipoProblema
                    ? `Editando: `
                    : 'Nuevo Tipo de Problema'
                }}
              </h3>
              <span
                v-if="value.tipoProblema.nombre"
                class="nombre-tipo-problema"
                v-html="value.tipoProblema.nombre"
              />
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-12 col-lg-8">
                  <v-row>
                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="El Problema debe incluir un nombre"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Nombre
                            </label>
                          </template>
                          <span>Nombre del Tipo de Problema</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.tipoProblema.nombre"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:50"
                        name="El Problema debe incluir un enlace"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Enlace<span class="label-span">
                                (<b>formato:</b>
                                nuevo-tipo-problema-enlace)</span>
                            </label>
                          </template>
                          <span>URL que se asignará al Tipo de Problema. Use textos
                            sin espacios, en minúsculas y separados por guión,
                          </span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.tipoProblema.toLink"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:250"
                        name="El Problema debe incluir un Meta Title"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Meta Title
                            </label>
                          </template>
                          <span>
                            Título a mostrar en pestaña (considerar SEO)</span>
                        </v-tooltip>

                        <v-text-field
                          v-model="value.tipoProblema.metaTitle"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:150"
                        name="Debe seleccionar un Título para la Sección Problemas"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Título Sección Problemas
                            </label>
                          </template>
                          <span>Título verde de Problemas</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.tipoProblema.problemasTitulo"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>

                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="max:150"
                        name="Debe seleccionar un Título para la Sección Tratamiento"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Título Sección Tratamiento
                            </label>
                          </template>
                          <span>Título verde de Tratamiento (sólo si incluye esta
                            sección)</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.tipoProblema.tratamientoTitulo"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>

                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="max:150"
                        name="Debe seleccionar un Título para la Sección Causas"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                            >
                              Título Sección Causas
                            </label>
                          </template>
                          <span>Título verde de Causas (sólo si incluye esta
                            sección)</span>
                        </v-tooltip>
                        <v-text-field
                          v-model="value.tipoProblema.causasTitulo"
                          :error-messages="errors"
                          color="success"
                          type="text"
                          filled
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      v-if="value.tipoProblema.imagen"
                      class="col-12 col-sm-6"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="label-img"
                            v-on="on"
                          >Imagen actual:
                            <span
                              v-html="value.tipoProblema.imagen"
                            /></label>
                        </template>
                        <span>La imagen actual del Tipo de Poblema. Puede cambiarla
                          en el box "Cambiar Imagen"</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="col-12 col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Archivo"
                        rules="ext:jpg,png|size:1000"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="grey--text"
                              v-on="on"
                              v-html="
                                value.tipoProblema.imagen
                                  ? 'Cambiar Imagen'
                                  : 'Subir Imagen'
                              "
                            />
                          </template>
                          <span>Cambia la imagen actual asociada al Tipo de
                            Problema</span>
                        </v-tooltip>
                        <v-file-input
                          v-model="value.imagen"
                          small-chips
                          :error-messages="errors"
                          label="Formato: jpg, png (1200x400px sugeridos)"
                          dense
                          filled
                          color="success"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12">
                      <h4>Descripción</h4>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:7000"
                        name="Descripcion"
                      >
                        <vue-editor
                          v-model="value.tipoProblema.descripcion"
                          :error-messages="errors"
                          :editor-toolbar="editorToolbarOptions"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="max:7000"
                        name="Tratamiento"
                      >
                        <h4>
                          Tratamiento
                          <span class="validation-error-title">{{
                            errors[0]
                          }}</span>
                        </h4>
                        <vue-editor
                          v-model="value.tipoProblema.tratamiento"
                          :error-messages="errors"
                          :editor-toolbar="editorToolbarOptions"
                          :class="
                            errors.length > 0 ? 'validation-error-box' : ''
                          "
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="col-12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="max:7000"
                        name="Causas"
                      >
                        <h4>
                          Causas
                          <span class="validation-error-title">{{
                            errors[0]
                          }}</span>
                        </h4>
                        <vue-editor
                          v-model="value.tipoProblema.causas"
                          :error-messages="errors"
                          :editor-toolbar="editorToolbarOptions"
                          :class="
                            errors.length > 0 ? 'validation-error-box' : ''
                          "
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="col-12 col-lg-4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h4
                        v-bind="attrs"
                        class="grey--text"
                        v-on="on"
                      >
                        Problemas Asociados
                      </h4>
                    </template>
                    <span>Problemas asociados a este tipo (escoja al menos
                      uno)</span>
                  </v-tooltip>
                  <div class="chk-problemas__main">
                    <v-checkbox
                      v-for="(item, i) in value.problemas"
                      :key="i"
                      v-model="item.checked"
                      :label="item.NombreProblema"
                      color="success"
                      hide-details
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="cerrar()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="success"
                default
                type="submit"
                :disabled="invalid"
                :loading="value.loader"
              >
                Grabar
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
  import { VueEditor } from 'vue2-editor'
  export default {
    name: 'ShowDialogTipoProblema',
    components: {
      VueEditor,
    },
    props: {
      value: {
        active: {
          type: Boolean,
          default: false,
        },
        tipoProblema: {
          type: Object,
          default: false,
        },
        problemas: {
          type: Array,
          default: false,
        },
        imagen: {
          type: Object,
          default: false,
        },
        loader: {
          type: Boolean,
          default: false,
        },
        default: null,
        type: Object,
      },
    },
    data () {
      return {
        editorToolbarOptions: require('@/data/editorToolbarOptions.json'),
      }
    },
    watch: {
      'value.active': function (value) {
        requestAnimationFrame(() => {
          // Reseteamos vee al cerrar dialog
          this.$refs.observer.reset()
        })
      },
    },
    methods: {
      validar () {
        this.$emit('procesar', this.value)
      },
      cerrar () {
        this.$emit('cerrar')
      },
    },
  }
</script>

<style lang="sass" scoped>
@import '@/sass/variables2'
.pre-formatted
  white-space: pre

.validation-error-box
  border: 1px solid #ff6668

.validation-error-title
  color: #ff6668

.ql-editor
  height: 200px !important

.chk-problemas__main
  overflow-y: scroll
  height: 1220px

.label-img
  color: #9e9e9e
  span
    color: $orange
.v-card
  .dialog-problema-titulo
    h3
      color: $green !important
      display: inline
    span
      color: #9e9e9e
      text-transform: capitalize
      font-weight: bold
.label-span
    font-size: 13px !important
    color: $green
</style>
